<template>
  <div class="view">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'company', params: {} }">
        Company
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'companyView', params: {} }">
        View Company
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="loaded">
      <v-card-title> View Company </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-if="company.name">
                <td max-width="35%" class="text-subtitle-1">Name:</td>
                <td>{{ company.name }}</td>
              </tr>
              <tr v-if="company.thaiName">
                <td max-width="35%" class="text-subtitle-1">Thai Name:</td>
                <td>{{ company.thaiName }}</td>
              </tr>
              <tr v-if="company.chineseName">
                <td max-width="35%" class="text-subtitle-1">Chinese Name:</td>
                <td>{{ company.chineseName }}</td>
              </tr>

              <tr v-if="company.type">
                <td max-width="35%" class="text-subtitle-1">Type:</td>
                <td>{{ company.type }}</td>
              </tr>

              <tr v-if="company.email">
                <td max-width="35%" class="text-subtitle-1">Email:</td>
                <td>{{ company.email }}</td>
              </tr>

              <tr v-if="company.address">
                <td max-width="35%" class="text-subtitle-1">Address:</td>
                <td>{{ company.address }}</td>
              </tr>

              <tr v-if="company.phone1">
                <td max-width="35%" class="text-subtitle-1">Phone 1:</td>
                <td>{{ company.phone1 }}</td>
              </tr>

              <tr v-if="company.phone2">
                <td max-width="35%" class="text-subtitle-1">Phone 2:</td>
                <td>{{ company.phone2 }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between ma-5">
      <v-btn @click="goBack">Back</v-btn>
      <v-btn @click="edit(id)" color="primary">Edit</v-btn>
      <span></span>
    </div>
  </div>
</template>

<script>
import { getCompany } from "@/services/company";

export default {
  data() {
    return {
      id: "",
      company: {},
      loaded: false,
      relations: {},
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    edit(id) {
      this.$router.push({ name: "companyEdit", params: { id: id } });
    },
    setInstance() {
      this.setDependencies().then(() => {
        getCompany(this.id)
          .then((response) => {
            this.company = response.data;
            this.loaded = true;
          })
          .catch(() => {
            this.addErrorMessage("Error loading company");
            this.loaded = true;
          });
      });
    },
    async setDependencies() {
      return null;
    },
    getRelationText(id, relationProperty, relationAttribute) {
      const relationList = this.relations[relationProperty];
      if (relationList) {
        const relationObj = relationList.find((r) => r.id == id);
        return relationObj[relationAttribute];
      } else {
        return "";
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.setInstance();
    this.$emit("showParent", false);
  },
};
</script>
